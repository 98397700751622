import * as React from 'react';
import './home.scss';
import { IComponent } from '../../interfaces/react-interfaces';
import * as ReactDOM from "react-dom";
import {Header} from "../../components/header/header";
import {Footer} from "../../components/footer/footer";
import {Routes} from "../../utils/routeUtils";
const content = require('../../../content/content.json');

export class Home extends React.Component<IComponent, {}> {
    render() {
        return (
            <div className="home-page" id="content">
                <h1>This site is calculated to make you happy.</h1>
                <h2>Sorry, it's not all together yet.</h2>
                <ul className="main-menu">
                    <li><a href={Routes.paintings}><img src={content.mainMenu.paintings}/><span className="menu-link">Paintings</span></a></li>
                    <li><a href={Routes.fairyTales}><img src={content.mainMenu.fairyTales}/><span className="menu-link">Fairy Tales</span></a></li>
                    <li><a href={Routes.plays}><img src={content.mainMenu.plays}/><span className="menu-link">Plays</span></a></li>
                    <li><a href={Routes.dreams}><img src={content.mainMenu.dreams}/><span className="menu-link">Dreams</span></a></li>
                    <li><a href={Routes.thoughts}><img src={content.mainMenu.thoughts}/><span className="menu-link">Thoughts</span></a></li>
                    <li><a href={Routes.about}><img src={content.mainMenu.me}/><span className="menu-link">Me</span></a></li>
                </ul>
            </div>
        );
    }
}

ReactDOM.render(<Header></Header>, document.getElementById('header'));
ReactDOM.render(<Home></Home>, document.getElementById('content'));
ReactDOM.render(<Footer></Footer>, document.getElementById('footer'));