import {IDictionary} from "../Interfaces/generics";
const config = require('../../config.json');

export const Routes: IDictionary<string> = {
    home: `${config.root}/`,
    fairyTales: `${config.root}/fairy-tales`,
    fairyTale: `${config.root}/fairy-tale`,
    about: `${config.root}/about`,
    plays: `${config.root}/plays`,
    play: `${config.root}/play`,
    paintings: `${config.root}/paintings`,
    dreams: `${config.root}/dreams`,
    thoughts: `${config.root}/thoughts`,
    scene: `${config.root}/scene`
};