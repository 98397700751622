import * as React from 'react';
import './footer.scss';
import { IComponent } from '../../interfaces/react-interfaces';
import {Routes} from "../../utils/routeUtils";

export class Footer extends React.Component<IComponent, {}> {
    render() {
        return (
            <footer>
                <ul>
                    <li><a href={Routes.home}>Home</a></li>
                    <li><a href={Routes.about}>About</a></li>
                    <li><a href={Routes.fairyTales}>Fairy Tales</a></li>
                    <li><a href={Routes.plays}>Plays</a></li>
                    <li><a href={Routes.dreams}>Dreams</a></li>
                </ul>
                <div className="copyright">&copy;2018 William Beggs</div>
            </footer>
        );
    }
}