import * as React from 'react';
import './header.scss';
import { IComponent } from '../../interfaces/react-interfaces';
import  {Routes} from "../../utils/routeUtils";

export class Header extends React.Component<IComponent, {}> {
    render() {
        return (
            <header>
                <div>
                    <h1 className="page-title">William Beggs</h1>
                </div>
                <nav>
                    <ul>
                        <li><a href={Routes.home}>Home</a></li>
                        <li><a href={Routes.about}>About</a></li>
                    </ul>
                </nav>
            </header>
        );
    }
}